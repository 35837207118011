// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
/* eslint complexity: ["error", 28] */
import { Card as CbhCard, CbhIcon, Divider, Pill } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own Card, ListItemButton, Pill instead of Chip
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Card,
  CardContent,
  // eslint-disable-next-line no-restricted-imports
  Chip,
  // eslint-disable-next-line no-restricted-imports
  ListItemButton,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory, useParams } from "react-router-dom";

import { DeprecatedGlobalAppV1Paths } from "../../App/paths";
import { formatCentsAsUsd } from "../../lib/Money/utils/currency";
import { formatDistanceInMiles } from "../../lib/utils/distance";
import { Button } from "../components/Button";
import { useCreateOrGetPlacementCandidate } from "../PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { jobTypeOptions, shiftTypeOptions } from "../PlacementCandidate/constants";
import { useTrackMarketingLinkClicks } from "../PlacementCandidate/hooks/useTrackMarketingLinkClicks";
import { isShiftNameType, JobType, ShiftNameType } from "../PlacementCandidate/types";
import { transformPlacementData, useGetPlacement } from "./api/useGetPlacement";
import { EstimatedPayBottomSheet } from "./components/EstimatedPayBottomSheet";
import { InterviewIncentiveCard } from "./components/InterviewIncentiveCard";
import { PlacementApplicationMessage } from "./components/PlacementApplicationMessage";
import { PlacementApplicationStatusChip } from "./components/PlacementApplicationStatusChip";
import { PlacementApplyButtonContainer } from "./components/PlacementApplyButtonContainer";
import { PlacementChatWithWorkplace } from "./components/PlacementChatWithWorkplace";
import { PlacementDetailsSkeleton } from "./components/PlacementDetailsSkeleton";
import { PlacementInterviewMessage } from "./components/PlacementInterviewMessage";
import { PlacementLocationMap } from "./components/PlacementLocationMap";
import { TryAShiftCard } from "./components/TryAShiftCard";
import { PlacementSignOnBonus } from "./HiringReferral/PlacementSignOnBonus";
import { useComputedPayData } from "./lib/useComputedPay";
import { PLACEMENT_PERKS, PlacementPerk } from "./types/fetchPlacements.schema";
import { usePlacementsFilter } from "./usePlacementsFilter";
import {
  formatPayRange,
  getDefaultShiftTypeForDifferential,
  type GetDifferentialJobTraits,
  getOverallPayBreakdown,
  getPayRangeText,
  hasAppliedToPlacement,
  hasBookedInterview,
  interviewRequired,
  isApplicationCompleted,
} from "./utils";

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function PlacementDetailsPage() {
  const { placementId, placementCandidateId } = useParams<{
    placementId: string;
    placementCandidateId?: string;
  }>();
  const worker = useDefinedWorker();

  const {
    data: placement,
    isLoading: isPlacementLoading,
    isSuccess: isPlacementSuccess,
    refetch: refetchPlacement,
  } = useGetPlacement(
    { placementId, workerId: worker.userId },
    { enabled: isDefined(placementId) && isDefined(worker.userId) }
  );

  const { payData, isLoading: payDataIsLoading } = useComputedPayData(
    placement?.data ? [placement.data] : []
  );

  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  const placementDetails = isPlacementSuccess ? transformPlacementData(placement) : undefined;
  const hasApplied = hasAppliedToPlacement(placementDetails);
  const requiredInterview = interviewRequired(placementDetails);
  const bookedInterview = hasBookedInterview(placementDetails);
  const applicationCompleted = isApplicationCompleted(placementDetails);
  const isProspectWorkplace = Boolean(placementDetails?.workplace.placementSettings?.isProspect);

  const history = useHistory();

  const [placementsFilter] = usePlacementsFilter();

  const isLoading = isPlacementLoading || isPlacementCandidateLoading;
  const isSuccess = isPlacementSuccess && isPlacementCandidateSuccess;

  const showInterviewIncentiveCard =
    placementDetails?.interviewSettings?.attributes?.scheduleInterviews;

  useTrackMarketingLinkClicks({
    placementCandidate,
    workplaceId: placementDetails?.workplace.id,
    placementId: placementDetails?.id,
    enabled: isDefined(placementCandidate) && isDefined(placementDetails),
  });

  const payRate = payData.get(placementId);

  const estimatedPayBottomSheetModalState = useModalState();

  const placementsFilterShiftTypes = placementsFilter?.shiftTypes
    ?.split(",")
    .filter(isShiftNameType);

  const differentialShiftType = placementsFilterShiftTypes
    ? getDefaultShiftTypeForDifferential(placementsFilterShiftTypes)
    : ShiftNameType.AM;

  const differentialTraits: GetDifferentialJobTraits = {
    shiftTypes: differentialShiftType ? [differentialShiftType] : undefined,
    withBenefits: true,
    isWeekendShift: placementsFilter?.jobTypes?.includes(JobType.WEEKEND_WARRIOR) ?? false,
  };

  const payBreakdown = isDefined(payRate)
    ? getOverallPayBreakdown(payRate, differentialTraits)
    : undefined;

  const singlePayBreakdown = payBreakdown?.type === "single" ? payBreakdown : undefined;

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Job Details"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS} />}
        />
      }
      containerVariant="without-margin"
    >
      <ThemeProvider theme={getTheme()}>
        <Stack
          sx={(theme) => ({
            paddingY: 4,
            paddingX: 6,
            overflowY: "auto",
            paddingBottom:
              hasApplied || placementDetails?.isLicenseEligible !== false ? "200px" : "250px",
            backgroundColor: isLoading ? "inherit" : theme.palette.background.primary,
            minHeight: "100%",
          })}
          flex={1}
          spacing={6}
        >
          {isLoading && <PlacementDetailsSkeleton />}

          {isSuccess && placementDetails && (
            <Box>
              <Stack spacing={7}>
                <Stack spacing={2}>
                  <Text semibold variant="h3">
                    {placementDetails.title}
                  </Text>
                  {!payDataIsLoading &&
                    payRate &&
                    !(payRate.externallyDerived && isDefined(singlePayBreakdown)) && (
                      <Text variant="h5" sx={(theme) => ({ color: theme.palette.primary.main })}>
                        {getPayRangeText(payRate)}{" "}
                        {formatPayRange({
                          payRate,
                          showPerHour: true,
                          differentialTraits,
                        })}
                      </Text>
                    )}

                  <Text variant="body2" sx={(theme) => ({ color: theme.palette.text.primary })}>
                    Available for - {placementDetails.workerTypes?.join(", ")}
                  </Text>
                </Stack>
                <Stack spacing={3}>
                  {!payDataIsLoading &&
                    payRate &&
                    payRate.externallyDerived &&
                    !payRate.obscured &&
                    singlePayBreakdown && (
                      <Stack
                        sx={{
                          paddingBottom: 4,
                        }}
                      >
                        <CbhCard
                          variant="primary"
                          sx={(theme) => ({
                            border: `1px solid ${theme.palette.intent?.success.border}`,
                            py: 2,
                            px: 3,
                          })}
                        >
                          <CardContent>
                            <Stack spacing={7}>
                              <Stack direction="row" gap={2} alignItems="center">
                                <CbhIcon type="coin-filled" />
                                <Text
                                  variant="h6"
                                  sx={(theme) => ({
                                    color: theme.palette.text.primary,
                                    fontWeight: 500,
                                  })}
                                >
                                  Calculate your pay rate
                                </Text>
                              </Stack>
                              <Stack spacing={4}>
                                {Math.abs(
                                  singlePayBreakdown.overallPay - singlePayBreakdown.basePay
                                ) > 1 && (
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pr={4}
                                  >
                                    <Text variant="caption">Base rate</Text>
                                    <Text variant="body2">{`${formatCentsAsUsd(
                                      singlePayBreakdown.basePay
                                    )} /hr`}</Text>
                                  </Stack>
                                )}
                                {singlePayBreakdown.totalShiftTypeDifferential > 0 && (
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pr={4}
                                  >
                                    <Text variant="caption">Differential (shift type)</Text>
                                    <Text variant="body2">{`+ ${formatCentsAsUsd(
                                      singlePayBreakdown.totalShiftTypeDifferential
                                    )} /hr`}</Text>
                                  </Stack>
                                )}
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={(theme) => ({ height: theme.typography.h6.fontSize })}
                                >
                                  <Text variant="caption">Our default estimate</Text>
                                  <Pill
                                    label={
                                      <Text variant="body1">{`${formatCentsAsUsd(
                                        singlePayBreakdown.overallPay
                                      )} /hr`}</Text>
                                    }
                                    backgroundColor={(theme) =>
                                      theme.palette.intent?.success.background
                                    }
                                    color={(theme) => theme.palette.intent?.success.text}
                                  />
                                </Stack>
                              </Stack>
                              <Stack>
                                <Text variant="caption">
                                  Estimates are based on community-sourced pay data and not a
                                  guarantee.
                                </Text>
                                <Text variant="caption">
                                  {singlePayBreakdown.totalShiftTypeDifferential > 0
                                    ? "Use the calculator to get a rough pay estimate based on based on your years of experience and shift type preference"
                                    : "*For this job, differential data is not available so pay estimates might not be exact"}
                                </Text>
                              </Stack>
                              <Button
                                variant="outlined"
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.background.primary,
                                  border: `1px solid ${theme.palette.text.primary}`,
                                })}
                                onClick={() => {
                                  estimatedPayBottomSheetModalState.openModal();
                                }}
                              >
                                Calculate your precise estimate
                              </Button>
                            </Stack>
                          </CardContent>
                        </CbhCard>
                      </Stack>
                    )}

                  <Stack direction="row" gap={3} flexWrap="wrap">
                    {placementDetails.shiftTypes.map((shiftType) => {
                      const shiftTypeOption = shiftTypeOptions.find(
                        (option) => option.value === shiftType
                      );

                      return (
                        <Chip
                          key={shiftType}
                          label={shiftTypeOption?.label ?? shiftType}
                          variant="outlined"
                          sx={(theme) => ({
                            backgroundColor: theme.palette.background.tertiary,
                          })}
                        />
                      );
                    })}
                  </Stack>

                  <Stack direction="row" gap={3} flexWrap="wrap">
                    {placementDetails.jobTypes.map((type) => {
                      const jobTypeOption = jobTypeOptions.find((option) => option.value === type);

                      return (
                        <Chip
                          key={type}
                          label={jobTypeOption?.label ?? type}
                          variant="outlined"
                          sx={(theme) => ({
                            backgroundColor: theme.palette.background.tertiary,
                          })}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                {/* To show when application is not completed and booking an interview is required and no interview has been booked OR not applied */}
                {requiredInterview &&
                  !applicationCompleted &&
                  (!hasApplied || !bookedInterview) && (
                    <Card
                      variant="elevation"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.intent?.success.background,
                        color: theme.palette.intent?.success.text,
                        border: `1px solid ${theme.palette.intent?.success.text}`,
                      })}
                    >
                      <CardContent>
                        <Stack direction="row" spacing={3} alignItems="start">
                          <Box>
                            <CbhIcon type="bolt-filled" size="small" sx={{ mt: "2px" }} />
                          </Box>
                          <PlacementInterviewMessage
                            isProspectWorkplace={Boolean(
                              placementDetails.workplace.placementSettings?.isProspect
                            )}
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                  )}
                {/* To show when applied and application is not completed and either interview is required or interview has been booked */}
                {hasApplied && !applicationCompleted && (!requiredInterview || bookedInterview) && (
                  <Card
                    variant="elevation"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.tertiary,
                    })}
                  >
                    <CardContent>
                      <Stack direction="row" spacing={3} alignItems="center">
                        <Box>
                          <CbhIcon type="plane" size="small" />
                        </Box>
                        <PlacementApplicationMessage
                          interviews={placementDetails.interviews}
                          workplaceName={placementDetails.workplace.name}
                          workerTimezone={worker.tmz}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                )}
                <Divider />
                <Stack spacing={2}>
                  <ListItemButton
                    sx={{ p: 0 }}
                    onClick={() => {
                      history.push(
                        `/home-v2/placements/workplaces/${placementDetails.workplace.id}/profile`
                      );
                    }}
                  >
                    <Stack spacing={2} flex={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Text semibold variant="h5">
                          {placementDetails.workplace.name}
                        </Text>
                        <CbhIcon type="chevron-right" />
                      </Stack>
                      <Text
                        variant="body2"
                        sx={(theme) => ({ color: theme.palette.text.secondary })}
                      >
                        {formatDistanceInMiles(placementDetails.distance, "long")} from home
                      </Text>
                    </Stack>
                  </ListItemButton>

                  {isDefined(placementDetails.applications) &&
                    placementDetails.applications.length > 0 && (
                      <PlacementApplicationStatusChip
                        status={placementDetails.applications[0].attributes.status}
                      />
                    )}
                </Stack>
                <PlacementChatWithWorkplace
                  placementId={placementDetails.id}
                  workplaceId={placementDetails.workplace.id}
                />

                <Card variant="outlined">
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <PlacementLocationMap
                        coordinates={placementDetails.coordinates}
                        formattedAddress={placementDetails.address.formatted ?? ""}
                        placementId={placementId}
                        placementCandidateId={placementCandidateId}
                      />
                    </Stack>
                  </Stack>
                </Card>

                {showInterviewIncentiveCard && (
                  <InterviewIncentiveCard isProspectWorkplace={isProspectWorkplace} />
                )}

                <TryAShiftCard
                  placementDetails={placementDetails}
                  placementCandidate={placementCandidate}
                />

                {isDefined(placementDetails.perks) && placementDetails.perks.length > 0 && (
                  <Card
                    variant="elevation"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.tertiary,
                    })}
                  >
                    <CardContent>
                      <Stack spacing={7}>
                        <Text semibold variant="h5">
                          Highlights
                        </Text>

                        <Stack spacing={2} alignItems="flex-start">
                          {isDefined(placementDetails.signOnBonus) &&
                            isDefined(placementDetails.signOnBonus.amountInMinorUnits) && (
                              <Chip
                                label={`Sign-on Bonus: ${formatCentsAsUsd(
                                  placementDetails.signOnBonus.amountInMinorUnits
                                )}`}
                                variant="filled"
                                color="default"
                                icon={<CbhIcon type="extra-pay" size="small" />}
                              />
                            )}
                          {placementDetails.perks
                            .filter(
                              (perk) =>
                                !(
                                  perk === PlacementPerk.SIGN_ON_BONUS &&
                                  isDefined(placementDetails.signOnBonus?.amountInMinorUnits)
                                )
                            )
                            .map((perk) => {
                              const perkOption = PLACEMENT_PERKS.find(
                                (option) => option.value === perk
                              );

                              return (
                                <Chip
                                  key={perk}
                                  label={perkOption?.label ?? perk}
                                  variant="filled"
                                  color="default"
                                  icon={
                                    perkOption?.icon && (
                                      <CbhIcon type={perkOption.icon} size="small" />
                                    )
                                  }
                                />
                              );
                            })}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                )}
                <PlacementSignOnBonus
                  placementDetails={placementDetails}
                  getHiredStates={["PENDING"]}
                  referAFriendStates={["INELIGIBLE", "INACTIVE", "PAID"]}
                />
              </Stack>
            </Box>
          )}
        </Stack>
        {isSuccess && placementDetails && placementCandidate && (
          <PlacementApplyButtonContainer
            placementDetails={placementDetails}
            placementCandidate={placementCandidate}
            refetchPlacement={refetchPlacement}
          />
        )}
        {placement && estimatedPayBottomSheetModalState.modalIsOpen ? (
          <EstimatedPayBottomSheet
            modalState={estimatedPayBottomSheetModalState}
            placement={placement.data}
          />
        ) : undefined}
      </ThemeProvider>
    </PageWithHeader>
  );
}
