// TODO: Use our own Button

import { App } from "@capacitor/app";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
// eslint-disable-next-line no-restricted-imports
import { Box, Button, Stack, ThemeProvider } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";
import { useEffect } from "react";

import { useLocalStorage } from "../../lib/utils";
import { useDebounce } from "../../lib/utils/useDebounce";
import { useTrackMarketingLinkClicks } from "../PlacementCandidate/hooks/useTrackMarketingLinkClicks";
import { type PlacementCandidate } from "../PlacementCandidate/types";
import { useIsShiftDiscoveryEnabled } from "../ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { getSafeAreaPaddings } from "../utils/getSafeAreaPaddings";
import { FilterButton } from "./components/FilterButton";
import { PlacementListView } from "./components/PlacementListView";
import { PlacementMapView } from "./components/PlacementMapView";
import { usePlacementsFilter } from "./usePlacementsFilter";

interface PlacementsPageProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

/*
 TODO: Right now to make the bottom filters properly positioned, it's almost impossible due to a few factors:
 - This view renders inside old UI IonPage (which makes fixed positioning not account for the navbar due to how IonPage works)
 - It also renders inside the new UI which has a different layout - fixed positioning won't work properly without knowing the navbar height
 - It uses `PageWithHeader` wrapper which doesn't set a height to the page container, preventing us from using 100% height + absolute positioning for bottom filters
 - Navbar height is not hardcoded value, we only store navbar item height in the theme
 To fix this we need to:
 1. Remove the `PageWithHeader` wrapper (will happen during full transition to new UI)
 2. Make the buttons absolutely positioned in a container with height: calc(100% - navbar height)
    This will work automatically like it does in shift discovery, with different page structure
  As a temporary solution we will just hardcode the height of the navbar here (without the bottom padding) and use safe area padding
  with the same bottom value as the bottom padding of the buttons
*/

const NAVBAR_HEIGHT = 64;
export const showApplicationsStorageKey = "jobs-show-applications";

export function resetApplicationTabVisibility(isInBackground: boolean) {
  if (isInBackground) {
    localStorage.removeItem(showApplicationsStorageKey);
  }
}

export function PlacementsPage(props: PlacementsPageProps) {
  const { placementCandidate, worker } = props;
  const [viewMode, setViewMode] = useLocalStorage<"list" | "map">("jobs-view-mode", "list");
  const [filter, setFilter] = usePlacementsFilter();
  const [searchByWorkplaceName, setSearchByWorkplaceName] = useLocalStorage<string>(
    "jobs-search-workplace-name",
    ""
  );
  const [showApplications, setShowApplications] = useLocalStorage<boolean>(
    showApplicationsStorageKey,
    false
  );

  useTrackMarketingLinkClicks({
    placementCandidate,
    enabled: isDefined(placementCandidate),
  });

  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  useLogEffect(USER_EVENTS.VIEWED_JOBS, {
    viewMode,
    filter,
    searchByWorkplaceName,
    showApplications,
  });

  const debouncedSearchByWorkplaceName = useDebounce({
    value: searchByWorkplaceName,
    debounceTimeInMs: 1000,
    onUpdateValue: (value) => {
      setSearchByWorkplaceName(value);
    },
  });

  useEffect(() => {
    const listener = App.addListener("appStateChange", (state) => {
      if (!state.isActive) {
        setShowApplications(false);
      }
    });

    return () => {
      void listener.then(async (listenerHandle) => {
        await listenerHandle.remove();
      });
    };
  }, [setShowApplications]);

  const isListViewMode = viewMode === "list";

  return (
    <ThemeProvider theme={getTheme()}>
      {isListViewMode ? (
        <PlacementListView
          placementCandidate={placementCandidate}
          worker={worker}
          filter={filter}
          setFilter={setFilter}
          searchByWorkplaceName={searchByWorkplaceName}
          setSearchByWorkplaceName={setSearchByWorkplaceName}
          showApplications={showApplications}
          setShowApplications={setShowApplications}
          debouncedSearchByWorkplaceName={debouncedSearchByWorkplaceName}
        />
      ) : (
        <PlacementMapView worker={worker} filter={filter} showApplications={showApplications} />
      )}

      <Box
        sx={(theme) => ({
          position: "fixed",
          bottom: `calc(${theme.spacing(3)} + ${isShiftDiscoveryEnabled ? NAVBAR_HEIGHT : 0}px)`,
          left: "50%",
          transform: "translateX(-50%)",
          width: "auto",
          maxWidth: "90%",

          ...(isShiftDiscoveryEnabled
            ? getSafeAreaPaddings({
                bottom: true,
                fallbackBottom: theme.spacing(5),
              })
            : {}),
        })}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "max-content" }}
            onClick={() => {
              const newViewMode = isListViewMode ? "map" : "list";
              setViewMode(newViewMode);
              logEvent(USER_EVENTS.TAPPED_JOBS_VIEW_MODE, {
                viewMode: newViewMode,
                filter,
                searchByWorkplaceName,
                showApplications,
              });
            }}
          >
            {isListViewMode ? "View Map" : "View List"}
          </Button>
          <FilterButton
            filter={filter}
            setFilter={setFilter}
            worker={worker}
            placementCandidateId={placementCandidate.id}
            searchByWorkplaceName={debouncedSearchByWorkplaceName}
          />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
