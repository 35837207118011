import { Stack } from "@mui/material";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { Navbar } from "../Navbar";
import { PlacementCandidatePage } from "../PlacementCandidate/Page";

export function PlacementsRootPage() {
  const worker = useDefinedWorker();

  return (
    <PageWithHeader
      appBarHeader={<AppBarHeader title="Find Work" />}
      containerVariant="without-margin"
      footer={<Navbar />}
      containerId="jobs-list-container"
    >
      <Stack sx={{ height: "100%", position: "relative" }}>
        <PlacementCandidatePage worker={worker} />
      </Stack>
    </PageWithHeader>
  );
}
